import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Three presentations were given in 2021:`}</p>
    <p><em parentName="p">{`One:`}</em>{`  `}<strong parentName="p">{`The lost 'continents' of the South Atlantic Ocean`}</strong></p>
    <p>{`Poster presented at the Netherlands Earth Science Congress (virtual), April 8-9. Here is the link to the poster itself: `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/NAC2021-CVR-final.pdf"
      }}>{`Reeves & Souza, 2021`}</a></p>
    <p><em parentName="p">{`Two:`}</em>{`  `}<strong parentName="p">{`The southern South Atlantic in the context of a holistic Gondwana dispersal model`}</strong></p>
    <p>{`Oral presentation given at the Geological Society in London, October 6. Here is the link to the two-page abstract with figures: `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/Reeves&SouzaOct2021-sept05.pdf"
      }}>{`Reeves & Souza, 2021a`}</a></p>
    <p><em parentName="p">{`Three:`}</em>{`  `}<strong parentName="p">{`How India parted company from Gondwana: constraints of space and time`}</strong></p>
    <p>{`Krishnanunni Memorial Lecture, October 27 (virtual). Sri Krishnanunni, former director general of the Geological Survey of India, passed away in 2019.  He left a legacy to
support a series of lectures of which this was the third.  He had studied for his MSc in Photogeology at ITC in Delft 1969-71, going on to help found the Indian Institute
of Remote Sensing, amongst many other achievements.  An abstract of the lecture is available here `}<a parentName="p" {...{
        "href": "http://www.reeves.nl/upload/KrishnanunniMemorialLecture.pdf"
      }}>{`Reeves, 2021`}</a>{`. The lecture itself may be followed on `}<a parentName="p" {...{
        "href": "https://youtu.be/EYWIvdaQ_vU"
      }}>{`YouTube`}</a>{`.`}</p>
    <p><em parentName="p">{`2022 January 15`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      